import React, { Component } from 'react';
import { Auth, API } from "aws-amplify";
import Badge from 'react-bootstrap/Badge';
import { Form, Button, Container, Col, FormGroup, Card, Spinner, Tabs, Tab, InputGroup, Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import ModalWrapper from './utility/ModalWrapper';
import Autosuggest from 'react-autosuggest';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';
import * as yup from 'yup';
import Map from './utility/Map';
import ManageAttachments from './utility/ManageAttachments';
import History from './History';
import {PDFButton} from './utility/PDFDocument';
import { Link, Redirect } from 'react-router-dom';
import {UserConsumer} from './utility/UserContext';
import CreateNewLocationModal from './utility/CreateNewLocationModal';
import {hasPOD, authorizationHeader, isPODStatus} from './utility/Helper';
import AutoResizeTextArea from './utility/AutoResizeTextArea';

export default class EditJob extends Component {
    state = {
        job: null,
        jobId: undefined,
        show: false,
        showPODFilesConfirmationModal: false,
        proceedToEditInModal: false,
        notProceedToEditInModal: false,
        client: '',
        location: '',
        latitude: '',
        longitude: '',
        locationItem: null,
        address: '',
        status: [],
        accounts: [],
        locations: [],
        clients: [],
        divisions: [],
        suggestions: [],
        history: [],
        notificationGroups: [],
        notificationGroupId: '',
        startDate: moment(),
        redirect: null,
        isStateManager: false,
        statuserror: false
    };

    setCoordinates = (latitude, longitude) => {
        //console.log('latlong', latitude);
        this.setState({latitude: latitude, longitude: longitude});
    }

    setClient = client => {
        this.setState({client: client});
    }

    setLocation = location => {
        this.setState({location: location});
    }

    setLocationItem = selected => {
        //console.log('selected', selected);
        this.setState({locationItem: selected});
    }

    renderSuggestions = suggestion => {
        let address = '';
        if(suggestion.street1 && suggestion.street1 !== '')
            address = suggestion.street1;
        if(suggestion.street2 && suggestion.street2 !== '' && (suggestion.street1 && suggestion.street1.trim().toLowerCase() !== suggestion.street2.trim().toLowerCase()))
            address = (address + ' ' + suggestion.street2).trim();
        if(suggestion.suburb && suggestion.suburb !== '')
            address = (address + ' ' + suggestion.suburb).trim();
        if(suggestion.zip && suggestion.zip !== '')
            address = (address + ', ' + suggestion.zip).trim();
        if(address.startsWith(','))
            address = address.substring(1).trim();
        return (
            <div><div className='small-font'><span className='bold-font'>Division: </span>{suggestion.division}</div><div className='small-font'><span className='bold-font'>Location Name: </span>{suggestion.name}</div><div className='small-font'><span className='bold-font'>Address: </span>{address}</div></div>
            )
    }

    setSuggestions = suggestions => {
        this.setState({suggestions: suggestions});
    }

    setShow = show => {
        this.setState({ show: show });
        if(show === false)
          this.props.refresh(true);
    }

    setshowPODFilesConfirmation = show => {
        this.setState( { showPODFilesConfirmationModal : show});
    }

    preventSubmit = event => {
        event.preventDefault();
    }

    setproceedToEdit = show => {
        this.setState({ proceedToEditInModal: show});
    }

    setNotificationGroup = group => {
        console.log(group);
        this.setState({ notificationGroupId: group });
    }
    
    editJob = async (values, actions) => {
        try{
            if(this.state.latitude !== '' && this.state.longitude !== '')
                {
                let client = this.state.accounts.filter(account =>
                    account.Name && account.AccountType.toLowerCase() === 'client' && account.Name.trim().toLowerCase() === values.location.trim().toLowerCase());
                // const client = this.state.accounts.filter(account =>
                //     account.ItemId === values.client);
                //console.log('client', client);
                // const staff = this.state.clients.filter(account =>
                //     account.AccountType.toLowerCase() === 'staff' && account.FullName.trim().toLowerCase() === values.staff.trim().toLowerCase());
                const staff = this.state.accounts.filter(account =>
                    account.ItemId === values.staff);
                //console.log('staff', staff);
                const status = this.state.status.filter(status => status.ItemId === values.status);
                // const location = this.state.locations.filter(location =>
                //     location.Name.trim().toLowerCase() === values.location.trim().toLowerCase() && location.ClientId === client[0].ItemId);
                //console.log('location', location);
                let clientId = client.length > 0 ? client[0].ItemId : '';
                let staffId = staff.length > 0 ? staff[0].ItemId : '';
                
                let recurringDays = '';
                ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'].map(d => {
                    if(values[d])
                    {
                        if(recurringDays.length > 0)
                            recurringDays = recurringDays + ',' + d;
                        else
                            recurringDays = d;
                    }
                });
                console.log('recurringDays', recurringDays);
                // var today = new Date();
                // var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                // var dateTime = date+' '+time;
                const myInitJob = {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                    },
                    body: {
                        jobs: [
                            {
                                ItemId: this.state.job.ItemId,
                                Name: values.location,
                                ConsignmentNumber: values.ref,
                                JobType: values.jobType,
                                DeliveryLocationId: this.state.locationItem.id,
                                ParentItemId: this.state.locationItem.divisionId,
                                Division: this.state.locationItem.division,
                                Modified: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                                Street1: values.street1,
                                Street2: values.street2,
                                Suburb: values.suburb,
                                Zip: values.zip,
                                Latitude: this.state.latitude,
                                Longitude: this.state.longitude,
                                Cartons: values.box,
                                PoR: values.por,
                                Priority: values.priority ? 'Urgent': 'Normal',
                                ClientCode: values.clientcode,
                                ReferenceNumbers: values.referencenums,
                                Notes: values.notes,
                                PODNotes: values.podnotes,
                                DeliveryDate: values.deliveryTimeSwitch ? moment(values.deliveryDate).format('YYYY-MM-DD') + ' ' + moment(values.deliveryTime).format('HH:mm:ss') : moment(values.deliveryDate).format('YYYY-MM-DD') + (values.jobType.includes("Pickup") ? ' 06:30:00': ' 16:30:00'),
                                HasDeliveryTime: values.deliveryTimeSwitch,
                                StatusId: values.status,
                                Status: status[0].Label,
                                StatusGroup: status[0].Type,
                                DriverId: values.staff,
                                DriverName: staff[0].FullName,
                                SMS: values.sms,
                                PointToPoint: values.pointToPoint,
                                RateOverride: values.pointToPoint === true ? values.rateOverride : 0,
                                RateOverride: values.pointToPoint === true ? values.rateOverride : 0,
                                IsRecurring: values.recurring ? 'true' : 'false',
                                IsDaily: (values.recurring && values.recurringType === 'daily') ? 'true' : 'false',
                                IncludeWeekends: (values.recurring && values.includeWeekends && values.recurringType === 'daily') ? 'true' : 'false',
                                IsWeekly: (values.recurring && values.recurringType === 'weekly') ? 'true' : 'false',
                                Days: values.recurring ? recurringDays : '',
                                ExpiryDate: (values.recurring && values.expirationSwitch) ? moment(values.expirationDate).format('YYYY-MM-DD') + ' 00:00:00' : '',
                                DeliveryType: values.deliveryType,
                                DeliveryConsignmentIds: values.deliveryConsignmentIds,
                                IsInterstateDelivery: values.interstateDeliverySwitch,
                                Length: values.interstateDeliverySwitch ? values.length : undefined,
                                Width: values.interstateDeliverySwitch ? values.width : undefined,
                                Height: values.interstateDeliverySwitch ? values.height : undefined,
                                LocationMobileNumber: values.locationMobileNumber ? values.locationMobileNumber : ''
                            }
                        ]
                        }
                };
                //console.log('init', myInitJob);
                const resultJob = await API.patch("api", "/jobs", myInitJob);
                //console.log("resultJob", resultJob);
                if(resultJob.result === 'success')
                    this.setState({redirect: '/jobs'});
                //this.props.navProp.changeJobPage('jobList');
            }
            else
            {
                actions.setStatus({msg: 'The address provided is not valid.'});
                actions.setSubmitting(false);
            }
        }catch(error)
        {
            console.log('error', error.message);
        }
        
    }

    // componentWillReceiveProps(newProps) {
    //     if(this.props.id !== newProps.id)
    //     {
    //         this.fetchJob();
    //         this.fetchHistory();
    //     }
    // }

    componentDidMount() {
        if(this.props.match.params.jobId !== undefined && this.props.match.params.jobId.length > 0)
        {
            this.setState({jobId: this.props.match.params.jobId}, function(){
                this.fetchJob();
                this.fetchStatus();
                this.fetchClients();
                this.fetchDivisions();
                this.fetchAccounts();
                this.fetchHistory();
                this.fetchNotificationGroups();
            })
        }
        
      }

    async fetchJob(){
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            if(Auth.user.attributes["custom:stateManagerString"] && Auth.user.attributes["custom:stateManagerString"] === 'True') {
                this.setState({isStateManager: true});
            }
            else
                this.setState({isStateManager: false});
                
            const res = await API.get("api", "/jobs/" + this.state.jobId, myInit);
            if(res["result"] === "failure" && res["errors"] && res["errors"] === "user is disabled")
            {
                Auth.signOut();
                this.props.auth.setAuthStatus(false);
                this.props.auth.setUser(null);
                this.props.auth.setName(null);
                this.props.history.push("/login");
            }
            const initial = {
                name: res.jobs[0].Name,
                id: res.jobs[0].DeliveryLocationId,
                division: res.jobs[0].Division,
                divisionId: res.jobs[0].ParentItemId,
                operatorId: res.jobs[0].OperatorId,
                street1: res.jobs[0].Street1,
                street2: res.jobs[0].Street2,
                state: res.jobs[0].State,
                suburb: res.jobs[0].Suburb,
                zip: res.jobs[0].Zip
            };
            this.setState({
                job: res.jobs[0],
                location: res.jobs[0].Name,
                locationItem: initial
                })
            
        }catch(err) {
            //console.log('jserror', err);
            this.setState(() => { throw err; })
        }
    }
      
    async fetchAccounts() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            const res = await API.get("api", "/accounts", myInit);
            this.setState({
                accounts: res.accounts
            })
        }catch(err) {
            //console.log('jserror', err);
            this.setState(() => { throw err; })
        }
    }

    async fetchClients() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            const res = await API.get("api", "/clients?ForAddJobPage=true", myInit);
            this.setState({
                clients: res.clients
            })
        }catch(err) {
            //console.log('jserror', err);
            this.setState(() => { throw err; })
        }
    }

    async fetchDivisions() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            const res = await API.get("api", "/divisions", myInit);
            this.setState({
                divisions: res.divisions
            })
        }catch(err) {
            //console.log('jserror', err);
            this.setState(() => { throw err; })
        }
    }

    fetchLocations = async (clientId) => {
        try{
            if(clientId !== '')
            {
                for (let i = 0; i < 20; i++){
                    if(this.state.job === null || this.state.divisions.length === 0){
                        await this.sleep(1000);
                    }
                    else
                        break;
                }
                if(clientId === undefined)
                    clientId = this.state.job.BillingClientId;
                const divisions = this.state.divisions.filter(d => d.ParentItemId === clientId);
                
                // const authHeader = await authorizationHeader();
                // const entities = divisions.map( async (division, index) => {
                //     try{
                //         let res;
                //         res = await API.get('api', '/locations/unique?ParentItemId=' + division.ItemId, authHeader);                        
                //         console.log('division',division.ItemId);
                //         return (
                //             {
                //                 entity: division.ItemId,
                //                 content: res
                //             }
                //         )
                //     }catch(err) {
                //         console.error('error', err);
                //         this.setState(() => { throw err; })
                //     }
                // });
                
                // const resultEntities = await Promise.all(entities);
                // //console.log('resultEntities',resultEntities);
                // const fetchedLocations = [];
                // resultEntities.forEach(r => {
                //     if(r.content.locationsunique && r.content.locationsunique.length > 0){
                //         r.content.locationsunique.forEach(l => {
                //             fetchedLocations.push(l);
                //         });
                //     }
                // });
                // this.setState({
                //     locations: fetchedLocations
                // })
            }
        }catch(error) {
            //console.log('jserror', err);
            this.setState(() => { throw error; })
        }
    }

    async fetchHistory() {
        try
        {
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            const res = await API.get("api", "/histories?ParentItemId=" + this.state.jobId, myInit);
            this.setState({
                history: res.histories
            })
        }catch(err) {
            //console.log('jserror', err);
            this.setState(() => { throw err; })
        }
    }

    async fetchStatus() {
        try{
            const myInit = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
            };
                
            const res = await API.get("api", "/status?IncludeUnallocated=true", myInit);
            this.setState({
                status: res.status.filter(ss => ss.IsActive)
                })
        }catch(err) {
            //console.log('jserror', err);
            this.setState(() => { throw err; })
        }
        
    }

    async fetchNotificationGroups() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            
            const res = await API.get("api", "/workgroups", myInit);
            this.setState({
                notificationGroups: res.workgroups
                })
        }catch(err) {
            this.setState(() => { throw err; })
        }        
    }

    getNotificationGroups() {        
        return this.state.notificationGroups.map((group, index) => {
           const { Name, ItemId } = group
           return (
               <option key={ItemId} value={ItemId} >{Name}</option>
           )
        })
    }

    getStaff(driverId) {        
        if(this.state.notificationGroupId === '')
        {
            const filtered = this.state.accounts.filter(user => user.AccountType.toLowerCase() === 'driver' && (user.Status === "active" || user.ItemId === driverId));
            return filtered.map((user, index) => {
                const { FullName, ItemId } = user
                return (
                    <option key={ItemId} value={ItemId} >{FullName}</option>
                )
            })
        }
        else{
            const filtered = this.state.notificationGroups.filter(group => group.ItemId === this.state.notificationGroupId)[0].Members.filter(user => user.AccountType.toLowerCase() === 'driver');
            return filtered.map(user => {
                const { FirstName, LastName, AccountId } = user
                return (
                    <option key={AccountId} value={AccountId} >{FirstName + ' ' + LastName}</option>
                )
            })
        }
    }

    getClients() {        
        return this.state.clients.map((client, index) => {
           const { ItemId, Name } = client
           return (
                <option key={ItemId} value={ItemId} >{Name}</option>
           )
        })
    }

     getStatus() {        
        //const filtered = this.state.status.filter(status => status.AccountType.toLowerCase() === 'staff');
        return this.state.status.map((status) => {
           const { Label, ItemId } = status
           return (
               <option key={ItemId} value={ItemId} >{Label}</option>
           )
        })
     }

    handleClientChange = id => {
        this.setState({ locations: [] });
    }

    handleUrgentChange = (pp, val, _setter) => {
        _setter("priority", pp);
        if(pp) {
            var _status = this.state.status.filter(s => s.Label === "In van for delivery")[0];  
            _setter("status", _status.ItemId);     
            toast.success("Status changed to 'In van for delivery'", {
                duration: 5000
            });     
        }
        else {
            // var _status = this.state.status.filter(s => s.ItemId === this.state.job.StatusId)[0];  
            // _setter("status", _status.ItemId);  
            this.state.statuserror = false;
        }        
        
    }

    handleStatusChange = (ss, val, _setter) => {
        _setter("status", ss);   
        if(val.priority) {
            var _status = this.state.status.filter(s => s.ItemId === ss)[0]; 
            this.state.statuserror = (_status.Type != 2 && _status.Type != 3);   
        }   
        else {
            this.state.statuserror = false;
        } 
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    render() {  
      const schema = yup.object().shape({
        location: yup.string()
            .required('Required'),
        clientcode: yup.string().max(20, "Max 20 characters"),
        staff: yup.string()
            .required('Required'),
        box: yup.number()
            .typeError('Must be number')
            .required('Required'),
        rateOverride: yup.number()
            .typeError('Must be number'),
        length: yup.number()
            .typeError('Must be number')
            .test('ok', 'Required', function(value){
                if(this.parent.interstateDeliverySwitch === true && this.parent.cubicCapacityMetresCubed === undefined && (value === undefined || value.length === 0)){
                    return false;
                }              
            return true;
            }),
        width: yup.number()
            .typeError('Must be number')
            .test('ok', 'Required', function(value){
                if(this.parent.interstateDeliverySwitch === true && this.parent.cubicCapacityMetresCubed === undefined && (value === undefined || value.length === 0)){
                    return false;
                }              
            return true;
            }),
        height: yup.number()
            .typeError('Must be number')
            .test('ok', 'Required', function(value){
                if(this.parent.interstateDeliverySwitch === true && this.parent.cubicCapacityMetresCubed === undefined && (value === undefined || value.length === 0)){
                    return false;
                }              
            return true;
            }),
      });
      if (this.state.redirect) 
            return <Redirect to={{pathname: this.state.redirect}} />
      if(this.state.job === null || this.state.status.length === 0)
      {
          return(
            <div><br/><br/><br/>
              <Spinner animation="border" />
            </div>
          );
      }
      else{
        return (
            <React.Fragment>
                <Toaster />
                <Formik
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                if(isPODStatus(values.status) && !this.state.proceedToEditInModal)
                {
                    this.setState({ proceedToEditInModal: false});
                    const existingFiles = await API.get("api", "/files?ParentItemId=" + this.state.job.ItemId, await authorizationHeader());
                    if(existingFiles['count'] === '0')
                        this.setshowPODFilesConfirmation(true);
                    else
                        await this.editJob(values, actions);
                }
                else
                {
                    if(this.state.proceedToEditInModal)
                        this.setshowPODFilesConfirmation(false);
                    await this.editJob(values, actions);
                }
            }}
            initialValues={{
                street1: this.state.job.Street1,
                street2: this.state.job.Street2,
                city: '',
                state: '',
                suburb: this.state.job.Suburb,
                zip: this.state.job.Zip,
                location: this.state.job.Name,
                division: this.state.job.Division,
                client: this.state.job.BillingClientId,
                deliveryTimeSwitch: this.state.job.HasDeliveryTime,//this.state.job.DeliveryDate.indexOf(' ') !== -1 ? true : false,
                billingClient:'',
                jobType:this.state.job.JobType,
                jobTitle:'',
                por: this.state.jobId !== '' ? this.state.job.PoR ? this.state.job.PoR : '' : '',
                clientcode: this.state.jobId !== '' ? this.state.job.ClientCode ? this.state.job.ClientCode : '' : '',
                referencenums: this.state.jobId !== '' ? this.state.job.ReferenceNumbers ? this.state.job.ReferenceNumbers : '' : '',
                notes:this.state.job.Notes === null ? '' : this.state.job.Notes,
                podnotes:this.state.job.PODNotes === null ? '' : this.state.job.PODNotes,
                ref: this.state.job.ConsignmentNumber,
                staff: this.state.job.DriverId == null ? '' : this.state.job.DriverId,
                deliveryDate: new Date(this.state.job.DeliveryDate),
                deliveryTime: this.state.job.HasDeliveryTime ? new Date(this.state.job.DeliveryDate) : (this.state.job.JobType.includes("Pickup") ? new Date(new Date().setHours(6, 30, 0)): new Date(new Date().setHours(16, 30, 0))),
                priority: (this.state.job.Priority && this.state.job.Priority === "Urgent") ? true : false,
                box:this.state.job.Cartons,
                status: this.state.job.StatusId,
                sms: this.state.job.SMS,
                pointToPoint: this.state.job.PointToPoint,
                rateOverride: this.state.job.RateOverride,
                recurring: this.state.job.IsRecurring.toLowerCase() === 'true' ? true : false,
                recurringType: this.state.job.IsWeekly.toLowerCase() === 'true' ? 'weekly' : 'daily',
                includeWeekends: this.state.job.IncludeWeekends.toLowerCase() === 'true' ? true : false,
                expirationSwitch: (this.state.job.ExpiryDate !== null && this.state.job.ExpiryDate !== '') ? true : false,
                expirationDate: (this.state.job.ExpiryDate !== null && this.state.job.ExpiryDate !== '') ? new Date(this.state.job.ExpiryDate) : new Date(),
                sunday: this.state.job.Days.split(',').filter(d => d === 'sunday').length > 0 ? true : false,
                monday: this.state.job.Days.split(',').filter(d => d === 'monday').length > 0 ? true : false,
                tuesday: this.state.job.Days.split(',').filter(d => d === 'tuesday').length > 0 ? true : false,
                wednesday: this.state.job.Days.split(',').filter(d => d === 'wednesday').length > 0 ? true : false,
                thursday: this.state.job.Days.split(',').filter(d => d === 'thursday').length > 0 ? true : false,
                friday: this.state.job.Days.split(',').filter(d => d === 'friday').length > 0 ? true : false,
                saturday: this.state.job.Days.split(',').filter(d => d === 'saturday').length > 0 ? true : false,
                deliveryType: this.state.job.DeliveryType,
                deliveryConsignmentIds: this.state.job.DeliveryConsignmentIds ? this.state.job.DeliveryConsignmentIds : '',
                interstateDeliverySwitch: (this.state.job.CubicCapacityMetresCubed !== null && this.state.job.CubicCapacityMetresCubed !== '') ? true : this.state.job.IsInterstateDelivery,
                length: this.state.job.Length === null ? undefined : this.state.job.Length,
                width: this.state.job.Width === null ? undefined : this.state.job.Width,
                height: this.state.job.Height === null ? undefined : this.state.job.Height,
                cubicCapacityMetresCubed: this.state.job.CubicCapacityMetresCubed === null ? undefined : this.state.job.CubicCapacityMetresCubed,
                locationMobileNumber: this.state.job.LocationMobileNumber === null ? undefined : this.state.job.LocationMobileNumber,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
              status,
              setFieldValue
            }) => (
            <React.Fragment>
            <Container className="no-left-right-margin form-text-align">
            <Tabs defaultActiveKey="edit" id="uncontrolled-tab-example">
                <Tab eventKey="edit" title={<div><h5>Edit Job</h5></div>}>
                <Form onSubmit={handleSubmit} >
                <UserConsumer>
                    {userType => {
                        return (
                            <Card className="no-top-border">
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4}>
                                            {userType.toLowerCase() !== 'globaladministrator' && userType.toLowerCase() !== 'companyadministrator' &&
                                            <FormGroup className="margin-bottom-zero">
                                                <Form.Label><h6 className="bold-font">Ref #: {values.ref}</h6></Form.Label>
                                            </FormGroup>
                                            }
                                            {(userType.toLowerCase() === 'globaladministrator' || userType.toLowerCase() === 'companyadministrator') &&
                                            <Form.Row>
                                                <FormGroup as={Col} xs={3}>
                                                    <Form.Label><h6 className="bold-font">Ref #: </h6></Form.Label>
                                                </FormGroup>                                                
                                                <Form.Group as={Col} controlId="ref" >
                                                    <Form.Control
                                                        size="sm"
                                                        type="text"
                                                        value={values.ref}
                                                        name="ref"
                                                        placeholder="Ref #"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.ref}
                                                        touched={touched.ref}
                                                        isInvalid={errors.ref && touched.ref}
                                                    >
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.ref}
                                                    </Form.Control.Feedback>
                                                </Form.Group> 
                                            </Form.Row>
                                            }
                                            {
                                                values.priority ? <p className="customBadge"><Badge bg="warning" text="dark">Urgent</Badge></p> : ""
                                            }
                                            <FormGroup className="margin-bottom-zero">
                                                <Form.Label><h5>Job Location Details</h5></Form.Label>
                                            </FormGroup>   
                                            
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="client" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Client</Form.Label>
                                                        <Form.Control as="select"
                                                        //disabled={isLoading}
                                                        size="sm"
                                                        type="text"
                                                        value={values.client}
                                                        readOnly={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeadministrator' || userType.toLowerCase() === 'officeuser')}
                                                        onChange={(e) => {
                                                            this.handleClientChange(e.target.value);
                                                            setFieldValue('client', e.target.value);
                                                            this.setLocation('');
                                                            this.setLocationItem(null);
                                                            setFieldValue('location', '');
                                                            setFieldValue('division', '');
                                                            setFieldValue('street1', '');
                                                            setFieldValue('street2', '');
                                                            setFieldValue('city', '');
                                                            setFieldValue('suburb', '');
                                                            setFieldValue('zip', '');
                                                        }}
                                                        placeholder="Loading..."
                                                        isInvalid={errors.client && touched.client}
                                                        >
                                                            <option value="">{this.state.clients.length === 0 ? "Loading..." : "Select Client"}</option>
                                                            {this.getClients()}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.client}
                                                        </Form.Control.Feedback>                                                        
                                                </Form.Group>
                                            </Form.Row>

                                            <FormGroup className="margin-bottom-8">
                                                <Form.Label className="med-font margin-bottom-zero">{values.jobType.toLowerCase().includes('pickup') ? 'Pickup Location' : 'Delivery Location'}</Form.Label>
                                                <Form.Row>
                                                    <FormGroup as={Col} xs={10} className="margin-bottom-zero">
                                                        <Autosuggest
                                                            inputProps={{
                                                                placeholder: (values.client === '' ? 'Please select client first.' : 'Search for a location.'),
                                                                readOnly: (userType.toLowerCase() !== 'driver' && userType.toLowerCase() !== 'officeadministrator' && userType.toLowerCase() !== 'officeuser') ? false : true,
                                                                name: 'location',
                                                                id: 'location',
                                                                autoComplete: 'abcd',
                                                                value: this.state.location,
                                                                className: touched.location && errors.location ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm',
                                                                onChange: (_event, {newValue}) => {
                                                                    this.setLocation(newValue);
                                                                    if(newValue === '') {
                                                                        this.setLocation('');
                                                                        this.setLocationItem(null);                                                            
                                                                        setFieldValue('location', '');
                                                                        setFieldValue('street1', '');
                                                                        setFieldValue('street2', '');
                                                                        setFieldValue('city', '');
                                                                        setFieldValue('suburb', '');
                                                                        setFieldValue('zip', '');
                                                                    }
                                                                },
                                                                onBlur: (_event, {newValue}) => {
                                                                    if(this.state.location.trim().toLowerCase() !== values.location.trim().toLowerCase())
                                                                    {
                                                                        this.setLocation('');
                                                                        this.setLocationItem(null);
                                                                        setFieldValue('location', '');
                                                                        setFieldValue('division', '');
                                                                        setFieldValue('street1', '');
                                                                        setFieldValue('street2', '');
                                                                        setFieldValue('city', '');
                                                                        setFieldValue('suburb', '');
                                                                        setFieldValue('zip', '');
                                                                    }
                                                                }
                                                            }}
                                                            suggestions={this.state.suggestions}
                                                            onSuggestionsFetchRequested={async ({value}) => {
                                                                if(!value){
                                                                    this.setSuggestions([]);
                                                                    return;
                                                                }
                                                                try{
                                                                    const inputValue = value.trim().toLowerCase();
                                                                    if(values.client != null && values.client !== '' && values.division != null && values.division !== '')
                                                                    {
                                                                        //const divisions = this.state.divisions.filter(d => d.ParentItemId === clientId);
                                                                        const authHeader = await authorizationHeader();
                                                                        let divisionId = this.state.divisions.filter(d => d.ParentItemId === values.client && d.Name === values.division)[0].ItemId;
                                                                        toast.dismiss();
                                                                        var toastId = toast.loading('Fetching Locations...');
                                                                        let res = await API.get('api', '/locations?pageSize=50&page=1&ParentItemId=' + divisionId + '&Sort=Modified~desc&Condition=SearchField~contains~' + inputValue, authHeader);
                                                                        if(res && res.locations && res.locations.length > 0) {
                                                                            toast.dismiss();
                                                                            this.setState({
                                                                                locations: res.locations
                                                                            }, () => {
                                                                                //console.log('filtered', filtered);
                                                                                this.setSuggestions(
                                                                                    res.locations.map(row => ({
                                                                                        name: row.Name,
                                                                                        id: row.ItemId,
                                                                                        division: row.Division,
                                                                                        divisionId: row.ParentItemId,
                                                                                        operatorId: row.OperatorId,
                                                                                        // billingClientId: row.BillingClientId,
                                                                                        // address: row.Address,
                                                                                        street1: row.Street1,
                                                                                        street2: row.Street2,
                                                                                        // city: row.City,
                                                                                        state: row.State,
                                                                                        suburb: row.Suburb,
                                                                                        zip: row.Zip
                                                                                    }))                                                
                                                                                );
                                                                            });
                                                                        }      
                                                                        else {
                                                                            toast.dismiss();
                                                                            toast.error("No locations found", {
                                                                                duration: 5000
                                                                            });
                                                                            this.setSuggestions([]);
                                                                        }                                                                           
                                                                    }                                                                               
                                                                }catch(e){
                                                                    this.setSuggestions([]);
                                                                }
                                                            }}
                                                            onSuggestionSelected={(event, {suggestion, method}) => {
                                                                if (method === 'enter') {
                                                                    event.preventDefault();
                                                                }
                                                                this.setLocation(suggestion.name);
                                                                this.setLocationItem(suggestion);
                                                                setFieldValue('location', suggestion.name);
                                                                // if(suggestion.billingClientId)
                                                                //     setFieldValue('billingClient', suggestion.billingClientId);
                                                                setFieldValue('division', suggestion.division);
                                                                setFieldValue('street1', suggestion.street1);
                                                                setFieldValue('street2', suggestion.street2);
                                                                setFieldValue('city', suggestion.city);
                                                                setFieldValue('suburb', suggestion.suburb);
                                                                setFieldValue('zip', suggestion.zip);
                                                            }}        
                                                            onSuggestionsClearRequested={() => {
                                                                this.setSuggestions([]);
                                                            }}
                                                            shouldRenderSuggestions={(value, reason) => {
                                                                return value.trim().length >= 4;
                                                            }}
                                                            getSuggestionValue={suggestion => suggestion.name}
                                                            renderSuggestion={(suggestion) => this.renderSuggestions(suggestion)}
                                                            // renderSuggestion={suggestion => <div><div>{suggestion.name}</div><div className='small-font'><span className='bold-font'>Address: </span>{suggestion.street1}</div></div>}
                                                        /> 
                                                        {errors.location && <div className="invalid-feedback-persist">{errors.location}</div>}
                                                    </FormGroup>
                                                    <FormGroup as={Col} className="margin-bottom-zero">
                                                        {
                                                            (userType.toLowerCase() === 'globaladministrator' || userType.toLowerCase() === 'companyadministrator' || userType.toLowerCase() === 'officeadministrator') && 
                                                            <CreateNewLocationModal selectedClient={values.client} selectedDivision={values.division} callBackFunctions={{setFieldValue:setFieldValue, setLocation:this.setLocation, setLocationItem:this.setLocationItem, setCoordinates:this.setCoordinates}} />
                                                        }
                                                    </FormGroup>
                                                </Form.Row>
                                            </FormGroup> 

                                            <Form.Row>                            
                                                <Form.Group as={Col} xs={12} controlId="billingClient" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Division</Form.Label>
                                                    {/* <Form.Control as="select" */}
                                                    <Form.Control
                                                        readOnly
                                                        size="sm"
                                                        type="text"
                                                        value={values.division}
                                                        name="division"
                                                        placeholder="Division"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.division}
                                                        touched={touched.division}
                                                        isInvalid={errors.division && touched.division}
                                                    >
                                                        {/* <option value="0">Choose...</option>
                                                        {this.getClients()} */}
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.division}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>               
                                            
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="street1" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Address 1</Form.Label>
                                                    <Form.Control
                                                        readOnly={this.state.job.Latitude !== null && (userType.toLowerCase() === 'driver'  || userType.toLowerCase() === 'officeuser')}
                                                        size="sm"
                                                        type="text"
                                                        placeholder="Address 1"
                                                        name="street1"
                                                        value={values.street1}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.street1 && touched.street1}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.street1}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} xs={12} controlId="street2" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Address 2</Form.Label>
                                                    <Form.Control
                                                        readOnly={this.state.job.Latitude !== null && (userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        size="sm"
                                                        type="text"
                                                        placeholder="Address 2"
                                                        name="street2"
                                                        value={values.street2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.street2 && touched.street2}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.street2}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="state" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Suburb</Form.Label>
                                                    <Form.Control 
                                                    readOnly={this.state.job.Latitude !== null && (userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                    size="sm"
                                                    type="text"
                                                    placeholder="Suburb"
                                                    name="suburb"
                                                    value={values.suburb}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.suburb && touched.suburb}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.suburb}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="zip" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Postcode</Form.Label>
                                                    <Form.Control 
                                                    readOnly={this.state.job.Latitude !== null && ((userType.toLowerCase() === 'driver' && !this.state.isStateManager)  || userType.toLowerCase() === 'officeuser')}
                                                    size="sm"
                                                    type="text"
                                                    placeholder="Postcode"
                                                    name="zip"
                                                    value={values.zip}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.zip && touched.zip}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.zip}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} xs={12} controlId="locationMobileNumber" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Location Mobile Number</Form.Label>
                                                    <Form.Control
                                                        size="sm"
                                                        type="text"
                                                        readOnly={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        name="locationMobileNumber"
                                                        value={values.locationMobileNumber}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.locationMobileNumber && touched.locationMobileNumber}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.locationMobileNumber}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group> 
                                        <Form.Group as={Col} xs={8}>
                                            <Map setCoordinates={this.setCoordinates} address={values.street1 + ' ' + (values.street2 ? values.street2 : '') + ' ' + values.suburb + ' ' + values.zip}/>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>                               
                                        <Form.Group as={Col} xs={6}>
                                            <FormGroup className="margin-bottom-zero">
                                                <Form.Label><h5>Job Details</h5></Form.Label>
                                            </FormGroup>                                
                                            <Form.Row>
                                                <Form.Group as={Col} xs={9} controlId="jobType" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Job Type</Form.Label>
                                                    <Form.Control as="select"
                                                        size="sm"
                                                        value={values.jobType}
                                                        name="jobType"
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.jobType}
                                                        touched={touched.jobType}
                                                        isInvalid={errors.jobType && touched.jobType}
                                                    >
                                                        <option>Delivery Carton</option>
                                                        <option>Delivery Pallet</option>
                                                        <option>Pickup Carton</option>
                                                        <option>Pickup Pallet</option>
                                                        <option>Return to Sender</option>
                                                        <option>Credo Pickup</option>
                                                        <option>Credo Delivery</option>
                                                        <option>Delivery Carton Ambient</option>
                                                        <option>Pickup Carton Ambient</option>
                                                        <option>Delivery Pallet Ambient</option>
                                                        <option>Pickup Pallet Ambient</option>
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.jobType}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} xs={3} controlId="box" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">{values.jobType.toLowerCase().includes('carton') ? 'Number of cartons' : 'Number of pallets'}</Form.Label>
                                                    <Form.Control 
                                                    size="sm"
                                                    type="text"
                                                    readOnly={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                    placeholder={values.jobType.toLowerCase().includes('carton') ? 'Number of cartons' : 'Number of pallets'}
                                                    name="box"
                                                    value={values.box}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.box && touched.box}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.box}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={9} controlId="deliveryType" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Delivery Type</Form.Label>
                                                    <Form.Control as="select"
                                                        size="sm"
                                                        value={values.deliveryType}
                                                        name="deliveryType"
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.deliveryType}
                                                        touched={touched.deliveryType}
                                                        isInvalid={errors.deliveryType && touched.deliveryType}
                                                    >
                                                        <option>Same Day</option>
                                                        <option>Next Day</option>
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.deliveryType}
                                                    </Form.Control.Feedback>                                                   
                                                </Form.Group>                                                
                                            </Form.Row>
                                            {values.jobType && values.jobType.includes("Pickup") &&
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="deliveryConsignmentIds" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Delivery Consignment Numbers</Form.Label>
                                                    {
                                                        (userType.toLowerCase() === 'globaladministrator' || userType.toLowerCase() === 'companyadministrator' || userType.toLowerCase() === 'officeadministrator')
                                                        ? <React.Fragment>
                                                            <AutoResizeTextArea onChange={setFieldValue} value={values.deliveryConsignmentIds} valueType='deliveryConsignmentIds' class='textarea-wide' placeholder='Enter delivery consignment numbers ...'/>
                                                        </React.Fragment>
                                                        : <React.Fragment>
                                                            <Form.Control 
                                                                size="sm"
                                                                type="text"
                                                                readOnly
                                                                name="deliveryConsignmentIds"
                                                                value={values.deliveryConsignmentIds}
                                                                isInvalid={errors.deliveryConsignmentIds && touched.deliveryConsignmentIds}
                                                                />
                                                        </React.Fragment>
                                                    }           
                                                </Form.Group>                                                
                                            </Form.Row>}
                                        </Form.Group>
                                        <Form.Group as={Col} xs={12}>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} className="margin-bottom-8">
                                                    <Form.Check 
                                                        className="med-font margin-bottom-zero"
                                                        defaultChecked={values.priority}
                                                        type="switch"
                                                        id="priority"
                                                        label="Mark as Urgent"
                                                        value={values.priority}
                                                        onChange={(e) => {
                                                            this.handleUrgentChange(e.target.checked, values, setFieldValue);
                                                        }}
                                                        />
                                                        {/* <span> This will set the status to 'In van for delivery' </span> */}
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} className="margin-bottom-8">
                                                    <Form.Check
                                                        disabled={(values.cubicCapacityMetresCubed !== undefined || (userType.toLowerCase() === 'driver'  || userType.toLowerCase() === 'officeuser')) ? true : false}
                                                        className="med-font margin-bottom-zero"
                                                        defaultChecked={values.interstateDeliverySwitch}
                                                        type="switch"
                                                        id="interstateDeliverySwitch"
                                                        label="Interstate Delivery"
                                                        value={values.interstateDeliverySwitch}
                                                        onChange={handleChange}
                                                        />
                                                </Form.Group>
                                            </Form.Row>
                                            {values.interstateDeliverySwitch === true && values.cubicCapacityMetresCubed === undefined &&
                                            <Form.Row>
                                                <Form.Group as={Col} xs={2} controlId="length" className="margin-bottom-15">
                                                    <Form.Label className="med-font margin-bottom-zero">Length</Form.Label>
                                                    <InputGroup className="mb-2">                                                    
                                                        <Form.Control
                                                        size="sm"
                                                        type="text"
                                                        readOnly={(userType.toLowerCase() === 'driver'  || userType.toLowerCase() === 'officeuser')}
                                                        placeholder=""
                                                        name="length"
                                                        value={values.length}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.length && touched.length}
                                                        />
                                                        <InputGroup.Text>cm</InputGroup.Text>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.length}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col} xs={2} controlId="width" className="margin-bottom-15">
                                                    <Form.Label className="med-font margin-bottom-zero">Width</Form.Label>
                                                    <InputGroup className="mb-2">                                                    
                                                        <Form.Control
                                                        size="sm"
                                                        type="text"
                                                        readOnly={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        placeholder=""
                                                        name="width"
                                                        value={values.width}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.width && touched.width}
                                                        />
                                                        <InputGroup.Text>cm</InputGroup.Text>
                                                        <Form.Control.Feedback type="invalid">
                                                        {errors.width}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>                                                    
                                                </Form.Group>
                                                <Form.Group as={Col} xs={2} controlId="height" className="margin-bottom-15">
                                                    <Form.Label className="med-font margin-bottom-zero">Height</Form.Label>
                                                    <InputGroup className="mb-2">                                                    
                                                        <Form.Control                                                
                                                        size="sm"
                                                        type="text"
                                                        readOnly={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        placeholder=""
                                                        name="height"
                                                        value={values.height}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.height && touched.height}
                                                        />
                                                        <InputGroup.Text>cm</InputGroup.Text>
                                                        <Form.Control.Feedback type="invalid">
                                                        {errors.height}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Form.Row>}
                                            {values.cubicCapacityMetresCubed !== undefined &&
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="cubicCapacityMetresCubed" className="margin-bottom-15">
                                                    <Form.Label className="med-font bold-font margin-bottom-zero">Cubic Capacity = {values.cubicCapacityMetresCubed}</Form.Label>                                                    
                                                </Form.Group>                                            
                                            </Form.Row>}
                                            <Form.Row>
                                                <Form.Group as={Col} xs={values.recurring ? 2 : 12} className="margin-bottom-8">
                                                    <Form.Check 
                                                        className="med-font margin-bottom-zero"
                                                        defaultChecked={values.recurring}
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        type="switch"
                                                        id="recurring"
                                                        label="Recurring"
                                                        value={values.recurring}
                                                        onChange={handleChange}
                                                        />
                                                </Form.Group>
                                                {values.recurring === true &&
                                                <React.Fragment>
                                                <Form.Group as={Col} xs={1} className="margin-bottom-zero">
                                                    <Form.Check 
                                                        className="med-font margin-bottom-zero"
                                                        defaultChecked={values.recurringType === 'daily' ? true : false}
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        type="radio"
                                                        id="daily"
                                                        label="Daily"
                                                        name="recurringType"
                                                        value="daily"
                                                        onChange={handleChange}
                                                        />
                                                </Form.Group>
                                                <Form.Group as={Col} xs={9} className="margin-bottom-zero">
                                                    <Form.Check 
                                                        className="med-font margin-bottom-zero"
                                                        defaultChecked={values.recurringType === 'weekly' ? true : false}
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        type="radio"
                                                        id="weekly"
                                                        label="Weekly"
                                                        name="recurringType"
                                                        value="weekly"
                                                        onChange={handleChange}
                                                        />
                                                </Form.Group>
                                                </React.Fragment>
                                                }
                                                {values.recurring === true && values.recurringType === 'daily' && 
                                                <Form.Group as={Col} xs={12} className="margin-bottom-8">
                                                    <Form.Check
                                                    defaultChecked={values.includeWeekends}
                                                    disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                    className="med-font margin-bottom-zero"
                                                    id="includeWeekends"
                                                    label="Include Weekends"
                                                    name="includeWeekends"
                                                    value={values.includeWeekends}
                                                    onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                }
                                                {values.recurring === true && values.recurringType === 'weekly' && 
                                                <React.Fragment>
                                                    <Form.Group as={Col} className="margin-bottom-8">
                                                        <Form.Check
                                                        defaultChecked={values.sunday}
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        className="med-font margin-bottom-zero"
                                                        id="sunday"
                                                        label="Sunday"
                                                        name="sunday"
                                                        value={values.sunday}
                                                        onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} className="margin-bottom-8">
                                                        <Form.Check
                                                        defaultChecked={values.monday}
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        className="med-font margin-bottom-zero"
                                                        id="monday"
                                                        label="Monday"
                                                        name="monday"
                                                        value={values.monday}
                                                        onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} className="margin-bottom-8">
                                                        <Form.Check
                                                        defaultChecked={values.tuesday}
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        className="med-font margin-bottom-zero"
                                                        id="tuesday"
                                                        label="Tuesday"
                                                        name="tuesday"
                                                        value={values.tuesday}
                                                        onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} className="margin-bottom-8">
                                                        <Form.Check
                                                        defaultChecked={values.wednesday}
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        className="med-font margin-bottom-zero"
                                                        id="wednesday"
                                                        label="Wednesday"
                                                        name="wednesday"
                                                        value={values.wednesday}
                                                        onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} className="margin-bottom-8">
                                                        <Form.Check
                                                        defaultChecked={values.thursday}
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        className="med-font margin-bottom-zero"
                                                        id="thursday"
                                                        label="Thursday"
                                                        name="thursday"
                                                        value={values.thursday}
                                                        onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} className="margin-bottom-8">
                                                        <Form.Check
                                                        defaultChecked={values.friday}
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        className="med-font margin-bottom-zero"
                                                        id="friday"
                                                        label="Friday"
                                                        name="friday"
                                                        value={values.friday}
                                                        onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} className="margin-bottom-8">
                                                        <Form.Check
                                                        defaultChecked={values.saturday}
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        className="med-font margin-bottom-zero"
                                                        id="saturday"
                                                        label="Saturday"
                                                        name="saturday"
                                                        value={values.saturday}
                                                        onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} className="margin-bottom-8"></Form.Group>
                                                    <Form.Group as={Col} className="margin-bottom-8"></Form.Group>
                                                    <Form.Group as={Col} className="margin-bottom-8"></Form.Group>
                                                </React.Fragment>
                                                }
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={2} controlId="deliveryDate" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">{values.jobType.toLowerCase().includes('pickup') ? 'Pickup Date' : 'Delivery Date'}</Form.Label><br/>
                                                    <DatePicker disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')} dateFormat="dd/MM/yyyy" selected={Date.parse(values.deliveryDate)} onChange={value => setFieldValue('deliveryDate', value)} className='form-control form-control-sm' placeholderText='Select delivery date'/>
                                                </Form.Group>
                                                <Form.Group as={Col} xs={2} controlId="deliveryTime" className="margin-bottom-8">
                                                    <Form.Check 
                                                    className="med-font margin-bottom-zero"
                                                    type="switch"
                                                    id="deliveryTimeSwitch"
                                                    disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                    label={values.jobType.toLowerCase().includes('pickup') ? 'Pick up by' : 'Deliver by'}
                                                    value={values.deliveryTimeSwitch}
                                                    defaultChecked={values.deliveryTimeSwitch}
                                                    onChange={handleChange}
                                                    />
                                                    <DatePicker readOnly={!values.deliveryTimeSwitch} disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm aa" selected={Date.parse(values.deliveryTime)} onChange={value => setFieldValue('deliveryTime', value)} className={values.deliveryTimeSwitch ? 'form-control form-control-sm' : 'form-control form-control-sm gray'} placeholderText='Select delivery time'/>
                                                    
                                                </Form.Group>
                                                {values.recurring === true && 
                                                <Form.Group as={Col} xs={2} controlId="expires" className="margin-bottom-8">
                                                    <Form.Check 
                                                        className="med-font margin-bottom-zero"
                                                        type="switch"
                                                        id="expirationSwitch"
                                                        label="Expires"
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        value={values.expirationSwitch}
                                                        defaultChecked={values.expirationSwitch}
                                                        onChange={handleChange}
                                                    />
                                                    {values.expirationSwitch &&
                                                    <DatePicker dateFormat="dd/MM/yyyy" disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')} readOnly={!values.expirationSwitch} selected={Date.parse(values.expirationDate)} onChange={value => setFieldValue('expirationDate', value)} className={values.expirationSwitch ? 'form-control form-control-sm' : 'form-control form-control-sm gray'} placeholderText='Select expiration date'/>
                                                    }
                                                    {!values.expirationSwitch &&
                                                    <Form.Control disabled={true} size="sm" type="text" placeholder="Never" name="dummyText"/>
                                                    }
                                                </Form.Group> 
                                                }
                                            </Form.Row>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={12}>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={4} controlId="pointToPoint" className="margin-bottom-8">
                                                    <Form.Check 
                                                    className="med-font margin-bottom-zero"
                                                    defaultChecked={values.pointToPoint}
                                                    disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                    type="switch"
                                                    id="pointToPoint"
                                                    label="Point To Point"
                                                    value={values.pointToPoint}
                                                    onChange={handleChange}
                                                    />
                                                    {values.pointToPoint === true &&  
                                                    <div>
                                                    <Form.Label className="med-font margin-bottom-zero">Rate Override</Form.Label>    
                                                    <Form.Control 
                                                    size="sm"
                                                    type="text"
                                                    readOnly={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeadministrator' || userType.toLowerCase() === 'officeuser')}
                                                    placeholder="Rate Override"
                                                    name="rateOverride"
                                                    value={values.rateOverride}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.rateOverride && touched.rateOverride}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.rateOverride}
                                                    </Form.Control.Feedback>
                                                    </div>}                                                                  
                                                </Form.Group>                                    
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={4} controlId="por" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">PoR</Form.Label>
                                                    {/* <Form.Control as="select" */}
                                                    <Form.Control                                                        
                                                        size="sm"
                                                        type="text"
                                                        value={values.por}
                                                        name="por"
                                                        readOnly={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        placeholder="PoR"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.por}
                                                        touched={touched.por}
                                                        isInvalid={errors.por && touched.por}
                                                    >
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.por}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={4} controlId="clientcode" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Client Code</Form.Label>
                                                    {/* <Form.Control as="select" */}
                                                    <Form.Control                                                        
                                                        size="sm"
                                                        type="text"
                                                        value={values.clientcode}
                                                        readOnly={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        name="clientcode"
                                                        placeholder="Client Code"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.clientcode}
                                                        touched={touched.clientcode}
                                                        isInvalid={errors.clientcode && touched.clientcode}
                                                    >
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.clientcode}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} controlId="referencenums" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Reference Numbers</Form.Label>
                                                    <Form.Control as="textarea" rows="3" 
                                                        size="sm"
                                                        readOnly={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        placeholder="Reference Numbers" 
                                                        name="referencenums"
                                                        value={values.referencenums}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Group controlId="description" className="margin-bottom-8">
                                                <Form.Label className="med-font margin-bottom-zero">Notes </Form.Label>
                                                <Form.Control as="textarea" rows="3" 
                                                    size="sm"
                                                    readOnly={(userType.toLowerCase() === 'driver'  || userType.toLowerCase() === 'officeuser')}
                                                    placeholder="Notes" 
                                                    name="notes"
                                                    value={values.notes}
                                                    onChange={handleChange} 
                                                />
                                            </Form.Group>
                                            <UserConsumer>
                                                {userType => {
                                                    let noHistory = false;
                                                    if(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')
                                                        noHistory = true;
                                                    return (
                                                        <Form.Group controlId="attachments" className="margin-bottom-8">
                                                            <ManageAttachments size='small' jobId={this.state.job.ItemId} />
                                                            {hasPOD(this.state.job) && 
                                                            <PDFButton size='small' type='download' attachments={this.state.job.Attachments} job={this.state.job} history={this.state.history} display='Download POD' noHistory={true} />  }                                 
                                                            {hasPOD(this.state.job) && 
                                                            <PDFButton size='small' type='view' attachments={this.state.job.Attachments} job={this.state.job} history={this.state.history} display='View POD' noHistory={noHistory} />}
                                                            {hasPOD(this.state.job) && 
                                                            <PDFButton size='small' type='email' attachments={this.state.job.Attachments} job={this.state.job} history={this.state.history} display='Email POD' noHistory={true} />}
                                                        </Form.Group>
                                                    )
                                                }}
                                            </UserConsumer>
                                            {/* <Form.Group controlId="attachments" className="margin-bottom-8">
                                                <ManageAttachments size='small' jobId={this.state.job.ItemId} />
                                                {this.state.job.Status.startsWith('POD') && 
                                                <PDFButton size='small' type='download' attachments={this.state.job.Attachments} job={this.state.job} history={this.state.history} display='Download POD' />  }                                 
                                                {this.state.job.Status.startsWith('POD') && 
                                                <PDFButton size='small' type='view' attachments={this.state.job.Attachments} job={this.state.job} history={this.state.history} display='View POD' />}
                                                {this.state.job.Status.startsWith('POD') && 
                                                <PDFButton size='small' type='email' attachments={this.state.job.Attachments} job={this.state.job} history={this.state.history} display='Email POD' />}
                                            </Form.Group> */}
                                            
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="notificationGroup" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Filter driver by Notification Group</Form.Label>
                                                    <Form.Control as="select"
                                                        size="sm"
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        value={this.state.notificationGroupId}
                                                        name="notificationGroup"
                                                        onChange={ (e) => {
                                                            this.setNotificationGroup(e.target.value);
                                                            setFieldValue('staff', '');
                                                        }}
                                                    >
                                                        <option value="">Filter by...</option>
                                                        {this.getNotificationGroups()}
                                                    </Form.Control>
                                                </Form.Group>                           
                                                <Form.Group as={Col} controlId="staff" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Select Driver</Form.Label>
                                                    <Form.Control as="select"
                                                        size="sm"
                                                        value={values.staff}
                                                        name="staff"
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.staff && touched.staff}
                                                    >
                                                        <option value="">Choose...</option>
                                                        {this.getStaff((this.state.job && this.state.job.DriverId) ? this.state.job.DriverId : "")}
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.staff}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="status" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Status</Form.Label>
                                                    <Form.Control as="select"
                                                        size="sm"
                                                        value={values.status}
                                                        disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                        name="status"
                                                        onChange={(e) => {
                                                            this.handleStatusChange(e.target.value, values, setFieldValue);
                                                        }}
                                                    >
                                                        {this.getStatus()}
                                                    </Form.Control>
                                                    { this.state.statuserror &&                                                        
                                                        <Form.Group>
                                                            <Form.Label className="error-msg error-font"> 
                                                                <i class="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>   
                                                                Job marked as "Urgent", consider resetting "Mark as Urgent" switch to update the status. 
                                                            </Form.Label>
                                                        </Form.Group>     
                                                    }
                                                </Form.Group>
                                            </Form.Row>
                                            { isPODStatus(values.status) &&
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="podnotes" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">POD Notes</Form.Label>
                                                    <Form.Control as="textarea" rows="3" 
                                                        size="sm"
                                                        placeholder="PODNotes" 
                                                        name="podnotes"
                                                        value={values.podnotes}
                                                        onChange={handleChange} 
                                                    />
                                                </Form.Group>
                                            </Form.Row> }
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="sms" className="margin-bottom-8">
                                                    <Form.Check 
                                                    className="med-font margin-bottom-zero"
                                                    type="switch"
                                                    disabled={(userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser')}
                                                    id="sms"
                                                    label="send SMS"
                                                    value={values.sms}
                                                    defaultChecked={values.sms}
                                                    onChange={handleChange}
                                                    />                                        
                                                </Form.Group>
                                            </Form.Row>
                                            {/* <br/><br/> */}
                                            {status && status.msg && 
                                            <Form.Group>
                                                <Form.Label className="error-msg">{status.msg}</Form.Label>
                                            </Form.Group>}

                                            {/* <Form.Row>
                                            <br/><br/>
                                            <span className="d-inline-block">
                                            <Button variant="primary" type="submit" disabled={isSubmitting} size="sm">
                                            {isSubmitting ? 'Loading…' : 'Save'}
                                            </Button>
                                            </span>
                                            &nbsp;&nbsp;
                                                    <span className="d-inline-block">
                                                        <Button variant="primary" onClick={this.props.onHide} size="sm">
                                                        Cancel
                                                        </Button>
                                                    </span>
                                            </Form.Row> */}
                                        </Form.Group>
                                        {/* <Form.Group as={Col} xs={8}>
                                            <Map address={values.street + ' ' + values.city + ' ' + values.state + ' ' + values.zip}/>
                                        </Form.Group> */}
                                    </Form.Row>
                                    
                                    <ModalWrapper
                                        show={this.state.show}
                                        size="sm"
                                        headercolor="lightgray"
                                        onHide={() => this.setShow(false)}
                                        modaltitle="Saved"
                                        type="message"
                                    />
                                {/* </Form> */}
                                </Card.Body>
                                <Card.Footer className="justify-content-end">
                                    <Form.Row>                                
                                        <span className="d-inline-block">
                                        <UserConsumer>
                                            {userType => {
                                                return (
                                                    <Button variant="primary" type="submit" disabled={ (isSubmitting && !this.state.notproceedToEditInModal ) || (userType.toLowerCase() === 'driver' && !this.state.isStateManager) ||  userType.toLowerCase() === 'officeuser' || this.state.statuserror } size="sm">
                                                    {(isSubmitting && !this.state.notproceedToEditInModal) ? 'Loading…' : 'Update'}
                                                    </Button>
                                                )
                                            }}
                                        </UserConsumer>
                                        </span>
                                        &nbsp;&nbsp;
                                        <span className="d-inline-block">
                                            <Button as={Link} to="/jobs" variant="primary" size="sm">
                                            Cancel
                                            </Button>
                                        </span>
                                        &nbsp;&nbsp;
                                        <span className="d-inline-block">                                        
                                        <Button as={(userType.toLowerCase() === 'driver'  || userType.toLowerCase() === 'officeuser') ? Button : Link} to={"/jobs/copyJob/" + this.state.job.ItemId} disabled={isSubmitting || userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'officeuser'} variant="primary" size="sm">
                                        Copy as New Job
                                        </Button>                                                
                                        </span>
                                        &nbsp;&nbsp;
                                        {(userType.toLowerCase() === 'globaladministrator' || userType.toLowerCase() === 'companyadministrator' || userType.toLowerCase() === 'officeadministrator') &&                                        
                                        <span className="d-inline-block">                                        
                                            <PDFButton type='view' variant='primary' isLabel={true} attachments={this.state.job.Attachments} job={this.state.job} history={this.state.history} display='Print Label' noHistory={true} />                                           
                                        </span>
                                        }                                        
                                    </Form.Row>
                                </Card.Footer>
                                <Modal
                                    size="lg"
                                    show={this.state.showPODFilesConfirmationModal}
                                    onHide={() => this.setsetshowPODFilesConfirmation(false)}
                                    backdrop= "static"
                                >
                                    <Modal.Body>
                                        <Form.Group as={Col} xs={12} className="margin-top-10 margin-bottom-zero">
                                            <Form.Label>There are no POD files for this consignment. You can add them by clicking "Manage attachments".
                                                        <br></br>Do you want to proceed without attaching any files?</Form.Label>                                
                                        </Form.Group>
                                        <Form.Group as={Col} xs={12} className="margin-top-10">
                                        <Button  className="mr-sm-2" variant="success" size="sm" onClick={() => {this.setproceedToEdit(true); handleSubmit()}}>
                                            Yes
                                        </Button>
                                        <Button type="button" className="mr-sm-2" variant="success" size="sm" onClick={(e) => { this.setState({notproceedToEditInModal: true}); this.setshowPODFilesConfirmation(false)} }>
                                            No
                                        </Button>
                                        </Form.Group>
                                    </Modal.Body>
                                </Modal>                                                        
                            </Card>
                        )
                    }}
                </UserConsumer>
                </Form>  
                </Tab>
                <Tab eventKey="history" title={<div><h5>History</h5></div>}>
                    <Card className="no-top-border">
                        <Card.Body>
                            <History history={this.state.history} job={this.state.job} showButtons={true} IsRecurring={"false"} hideNames={false} />
                        </Card.Body>                    
                    </Card>
                </Tab>
            </Tabs>           
            </Container>
            </React.Fragment>
            )}
            </Formik>
            </React.Fragment>
          
        );
        }
    }
}